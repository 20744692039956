import '../Components/ContactUs.css'
import React from 'react'
import contactUs from '../Components/Assets/Images/contactus.png'

const ContactUs = () => {
  return (
    <div>
      <h1 className="title title-contactus">Contact Us</h1>
      <div className="contact-us">
        <div className="div-left">
          <div>
            <label for="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter First and last name"
            />
          </div>
          <div>
            <label for="Enter your email address">Email Address</label>
            <input
              type="text"
              name="email"
              placeholder="Enter your email address"
            />
          </div>
          <div>
            <label for="message">Message</label>
            <textarea
              name="message"
              id="message"
              placeholder="Enter your message"
            ></textarea>
          </div>
          <button className="btn send-btn">Send</button>
        </div>
        <div className="div-right">
          <img
            src={contactUs}
            alt="Image illustrating contact information and support"
          />
        </div>
      </div>
    </div>
  );
}

export default ContactUs
