import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Header from "../Components/Header";
import AboutUs from "../Components/AboutUs";
import Footer from "../Components/Footer";
import Mission from "../Components/Mission";
import ContactUs from "../Components/ContactUs";
import { Helmet } from "react-helmet";

const Home = () => {
  <>
    <Helmet>
      <meta
        name="description"
        content="Welcome to CashGo, your go-to platform for financial empowerment and independence."
      />
      <meta property="og:title" content="Home - CashGo" />
      <meta
        property="og:description"
        content="Discover how CashGo can help you achieve financial success with innovative tools and resources."
      />
      <meta property="og:url" content="https://cashgo.com" />
      <link rel="canonical" href="https://cashgo.com" />
    </Helmet>
  </>;
  const { hash } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <div id="home">
      <Navbar />
      <Header />
      <AboutUs />
      <Mission />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
