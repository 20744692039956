import '../Components/Styles/Mission.css'
import React from 'react'
import stockRiseImg from '../Components/Assets/Images/first-img.png'
import integrity from "../Components/Assets/Images/second-img.png";
import bridge from "../Components/Assets/Images/third-img.png";

const Mission = () => {
  return (
    <div className="mission" id="mission">
      <div>
        <h1 className="title title-center">Our Mission</h1>
        <div className="card-container">
          <div className="card">
            <div>
              <img
                src={stockRiseImg}
                alt="Man working towards financial independence"
              />
            </div>
            <div>
              <h3>Empowering Financial Independence</h3>
              <p>
                To empower individuals and businesses by providing accessible,
                user-friendly financial tools that promote financial
                independence.
              </p>
            </div>
          </div>
          <div className="card">
            <div>
              <img
                src={integrity}
                alt="Image depicting a balance symbolizing integrity"
              />
            </div>
            <div>
              <h3>Innovating with Integrity</h3>
              <p>
                To drive financial innovation with a strong commitment to
                transparency and ethical practices.
              </p>
            </div>
          </div>
          <div className="card">
            <div>
              <img
                src={bridge}
                alt="Man and woman working together to build a bridge"
              />
            </div>
            <div>
              <h3>Bridging the Digital Financial Divide</h3>
              <p>
                To bridge the gap between traditional finance and modern digital
                solutions, making advanced financial services accessible to
                everyone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission
