import '../Components/Styles/Footer.css'
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
      <footer>
        <div className="footer">
          <small>
            {" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </small>
          <small>
            Copyright ©2024 Credility Financial Services Limited © All rights
            reserved
          </small>
        </div>
      </footer>
  );
}

export default Footer
