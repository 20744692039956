import React from "react";
import "./Styles/Navbar.css";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div>
      {/* <div className="container"> */}
      <div className="navbar">
          <h1>
            <Link to="/#home" className="logo">CREDILITY</Link>
          </h1>
        <nav>
          <ul>
            <li>
              <Link to="/#about-us">About Us</Link>
            </li>
            <li>
              <Link to="/#mission">Our mission</Link>
            </li>
          </ul>
        </nav>
        <button className="btn">Apply Now</button>
      </div>
    </div>
    // </div>
  );
};

export default Navbar;
