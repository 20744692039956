import './Styles/Header.css'
import React from 'react'
import globe from '../Components/Assets/Images/globe.png'

const Header = () => {
  return (
    <header>
      <div>
        <div className="row">
          <div className="top-div">
            <h1>Empowering Financial Innovation </h1>
            <p>
              Empowering individuals and businesses with innovative financial
              solutions.
            </p>
          </div>
          <div className="bottom-div">
            <img src={globe} alt="Futuristic green globe with glowing lights" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header
