import './Styles/AboutUs.css'
import React from 'react'
import aboutUsImage from './Assets/Images/back.png'
const AboutUs = () => {
  return (
    <section>
      <div id="about-us">
        <div className="aboutus">
          <div className="aboutus-row">
            <div className="left-div">
              <img src={aboutUsImage} alt="About Us - Image showcasing our team and mission" />

            </div>
            <div className="right-div">
              <h1 className="title">About us</h1>
              <p>
                Credility Financial Services Limited is a dynamic fintech
                company dedicated to Revolutionalizing the financial sector
                through innovative, technology-driven solutions. We strive to
                make financial services more accessible, secure, and
                user-friendly
              </p>
            </div>
          </div>
          <div className="about_us_text">
            <p>
              Empowering individuals and businesses to efficently manage their
              finances. With a strong focus on customer satisfaction and
              integrity, Credility leverages cutting-edge technology to
              streamline processes and deliver exceptional value, making us a
              leader in the evolving fintech landscape.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs
